import './contactscreate.css';
import { customElement, bindable, containerless } from 'aurelia-framework';
import { getLogger } from 'aurelia-logging';
import gql from 'graphql-tag';
import { GraphQLBaseViewModel } from '../../GraphQLBaseViewModel';
import {
  ContactType,
  ContactInput,
  ContactsCreateQuery,
  ContactsCreateQueryVariables,
  CreateNewContactVariables
} from '../../../../../custom_typings/graphql';
import { setSiteContact } from '../../../sitedetails/modules/sitedetailsindex/modules/sitedetailcontacts/modules/sitedetailcontactsutilities'
import { checkIfAnyContactsCanBeReUsed } from '../../../../components/contacts/contact-reuse-modal/contactreusemodal';
@containerless()
@customElement('contacts-create')
export class ContactsCreate extends GraphQLBaseViewModel<
  void,
  ContactsCreateQuery,
  ContactsCreateQueryVariables
> {
  constructor() {
    super(getLogger(ContactsCreate.name));
  }

  @bindable() cancelCreateContact: Function;
  @bindable() doneCreateContact: Function;
  @bindable() contactType: ContactType;
  @bindable() siteId: number;

  query = gql`
    query ContactsCreateQuery($siteId: Int!) {
      site(siteId: $siteId) {
        siteId
        customer {
          name
          customerId
        }
        buid {
          name
          buidId
        }
      }
    }
  `;

  pendingContact: ContactInput = {
    email: '',
    name: ''
  };

  bind() {
    this.pendingContact = {
      email: '',
      name: ''
    };
    if (this.siteId)
      this.variables = {
        siteId: this.siteId
      };
  }

  detached(): void {
    super.detached();
    this.showContactReuseUi = false;
  }

  pendingContactChanged = (
    property: keyof ContactInput,
    value: ContactInput[keyof ContactInput]
  ) => {
    this.pendingContact = {
      ...this.pendingContact,
      [property]: value
    };
  };

  saveButtonClicked = async () => {
    if (await checkIfAnyContactsCanBeReUsed(this.pendingContact, this.runQuery)) {
      this.showContactReuseUi = true;  // ask to re-use any existing contact
      return;
    }
    // No existing contact found, so just store this.pendingContact:
    await this.commitNewContact();
    this.doneCreateContact();
  };

  private commitNewContact = async () => {
    const variables: CreateNewContactVariables = {
      entity: {
        ...this.pendingContact,
        initAssignmentSiteId: this.siteId,
        initAssignmentContactType: this.contactType
      }
    };

    await this.runQuery(gql`
        mutation CreateNewContact($entity: ContactInput) {
          upsertContact(entity: $entity) {
            customerId
          }
        }
      `,
      variables
     );

    await this.revalidateAllActiveQueries();
  }

  showContactReuseUi: boolean = false;

  cancelReuseContact = (): void => {
    this.showContactReuseUi = false;
  }

  proceedCreatingNew = async () => {
    await this.commitNewContact();
    this.showContactReuseUi = false;
    this.doneCreateContact();
  }

  reuseExistingContact = async (contactId: string): Promise<void> => {
    try
    {
      const contactIdNumber = parseInt(contactId, 10);
      await setSiteContact(this.siteId, contactIdNumber, this.contactType);
      this.doneCreateContact();
    } catch(error){
      this.logger.error(JSON.stringify(error));
      throw error;
    }
  }
}
