import { GraphQLBaseViewModel } from '../../../../../common/GraphQLBaseViewModel';
import gql from 'graphql-tag';
import {
  HasEasyfeedRecipeQuery,
  HasEasyfeedRecipeQueryVariables
} from '../../../../../../../custom_typings/graphql';
import { autoinject, bindable, customElement } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { getLogger } from 'aurelia-logging';
import { routeNames } from '../../../../../../config';

@customElement('site-details-easyfeed-card')
@autoinject
export class SiteDetailsUsersWithAccess extends GraphQLBaseViewModel<
  void,
  HasEasyfeedRecipeQuery,
  HasEasyfeedRecipeQueryVariables
> {
  @bindable()
  siteId: number;

  currentWeekNumber: number | undefined | null;
  cropStage: string | undefined;

  constructor(private router: Router) {
    super(getLogger('EasyfeedCard'));
  }

  dataChanged = (data: HasEasyfeedRecipeQuery) => {
    if (!data) return;
    this.currentWeekNumber = data?.easyfeed?.currentWeek;
    this.cropStage = data?.easyfeed?.weeks?.find(
      w => w.weekNo === this.currentWeekNumber?.toString()
    )?.cropStage;
  };

  query = gql`
    query HasEasyfeedRecipeQuery($siteId: Int!) {
      easyfeed(siteId: $siteId) {
        name
        currentWeek
        weeks {
          cropStage
          weekNo
        }
      }
    }
  `;

  goToEasyfeed() {
    this.router.navigate(routeNames.sitedetailsEasyFeed);
  }

  bind() {
    this.variables = {
      siteId: this.siteId
    };
  }
}
