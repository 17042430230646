import './sitedetailcontactscreate.css';
import { customElement, bindable, containerless } from 'aurelia-framework';
import { ContactType } from '../../../../../../../../../custom_typings/graphql';

@containerless()
@customElement('sitedetailcontacts-create')
export class SitedetailcontactsCreate {
  @bindable() cancelCreateContact: Function;
  @bindable() doneCreateContact: Function;
  @bindable() contactType: ContactType;
  @bindable() siteId: number;
}
