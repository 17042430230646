import { customElement, bindable, containerless } from 'aurelia-framework';
import { GraphQLBaseViewModel } from '../../../../../../../common/GraphQLBaseViewModel';
import gql from 'graphql-tag';
import { isNone } from '../../../../../../../../utility';
import {
  SitedetailContactsQuery,
  ContactType,
  RemoveContactFromSiteMutation,
  SitedetailContactsQueryVariables,
  RemoveContactFromSiteMutationVariables
} from '../../../../../../../../../custom_typings/graphql';
import './sitedetailcontactsview.css';

@containerless()
@customElement('sitedetailcontacts-view')
export class SitedetailsContactsView extends GraphQLBaseViewModel<
  void,
  SitedetailContactsQuery,
  SitedetailContactsQueryVariables
> {
  @bindable({ changeHandler: 'bind' }) siteId: number;
  @bindable() changeContact: Function;

  contactTypes = ContactType;

  query = gql`
    fragment SitedetailsContactFragment on Contact {
      avatarUrl
      telephoneNumber
      email
      name
      userId
    }
    query SitedetailContactsQuery($siteId: Int!) {
      site(siteId: $siteId) {
        siteId
        orderContact {
          ...SitedetailsContactFragment
        }
        customerContact {
          ...SitedetailsContactFragment
        }
        techContact {
          ...SitedetailsContactFragment
        }
        salesContact {
          ...SitedetailsContactFragment
        }
        transportContact {
          ...SitedetailsContactFragment
        }
        dealerContact {
          ...SitedetailsContactFragment
        }
      }
    }
  `;

  hasContacts(data: SitedetailContactsQuery) {
    return (
      data &&
      data.site &&
      (data.site.customerContact ||
        data.site.orderContact ||
        data.site.techContact  ||
        data.site.transportContact ||
        data.site.dealerContact ||
        data.site.salesContact)
    );
  }

  bind() {
    if (isNone(this.siteId)) return;
    this.variables = {
      siteId: this.siteId
    };
  }

  removeContact = async (contactType: ContactType) => {
    const variables: RemoveContactFromSiteMutationVariables = {
      siteId: this.siteId.toString(),
      contactType
    };

    try {
      await this.runQuery<RemoveContactFromSiteMutation, RemoveContactFromSiteMutationVariables>(
        gql`
          mutation RemoveContactFromSiteMutation(
            $siteId: ID!
            $contactType: ContactType!
          ) {
            setContactOnSite(contactType: $contactType, siteId: $siteId)
          }
        `,
        variables
      );
      await this.revalidateAllActiveQueries();
    } catch(error) {
      this.logger.error(JSON.stringify(error));
      throw error;
    }
    
  };
}
