import { bindable, customElement } from 'aurelia-framework';
import { ContactType } from '../../../../../../../custom_typings/graphql';

@customElement('sitedetail-contacts')
export class SiteDetailContacts {
  @bindable() siteId: number;

  changingContactFor: undefined | ContactType;
  view: 'view' | 'search' | 'create' = 'view';

  changeContact = (contactType: ContactType) => {
    this.changingContactFor = contactType;
    this.view = 'search';
  };

  cancelChangingContact = () => {
    this.view = 'view';
    this.changingContactFor = undefined;
  };

  setContactDone = () => {
    this.view = 'view';
    this.changingContactFor = undefined;
  };

  doneCreateContact = () => {
    this.view = 'view';
    this.changingContactFor = undefined;
  };

  navigateToCreateForm = () => {
    this.view = 'create';
  };
}
