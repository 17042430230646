import { customElement, bindable, containerless } from 'aurelia-framework';
import './contactssearch.css';
import {
  ContactType,
  ContactsSearchQuery,
  ContactSortEnum,
  SortDirection,
  ContactsSearchQueryVariables,
  ContactsSearchQuery_contacts_edges
} from '../../../../../custom_typings/graphql';
import { GraphQLInfiniteListViewModel } from '../../GraphQLInfiniteListViewModel';
import { gql } from 'graphql-request';

@containerless()
@customElement('contacts-search')
export class ContactsSearch extends GraphQLInfiniteListViewModel<
  ContactsSearchQuery,
  ContactsSearchQueryVariables,
  ContactsSearchQuery_contacts_edges
> {

  constructor(){
    super(ContactsSearch.name, data => data.contacts.totalCount, data => data.contacts.edges)
  }
  @bindable() cancelSearch: Function;
  @bindable() contactType: ContactType;
  @bindable() siteId: number;
  @bindable() createNewContact: Function;

  @bindable() setAsContact: Function;

  textQuery = '';

  query = gql`
    query ContactsSearchQuery(
      $first: Int!
      $offset: Int!
      $freeTextQuery: String
      $sortProperty: ContactSortEnum!
      $sortDirection: SortDirection!
    ) {
      contacts(
        first: $first
        offset: $offset
        freeTextQuery: $freeTextQuery
        sortProperty: $sortProperty
        sortDirection: $sortDirection
      ) {
        totalCount
        edges {
          avatarUrl
          contactId
          email
          name
          telephoneNumber
          description
        }
      }
    }
  `;

  pageSize = 10;

  bind() {
    this.variables = {
      freeTextQuery: this.textQuery,
      sortProperty: ContactSortEnum.Name,
      sortDirection: SortDirection.Asc
    };
  }

  textQueryChanged(freeTextQuery: string) {
    if (!this.variables) return;
    this.variables = {
      ...this.variables,
      freeTextQuery
    };
  }
}
