import SiteDetailImagesReact, { ISiteDetailsGalleryProps } from './sitedetailimages.react';
import { ReactWrapper } from '$pages/common/react-wrapper';
import { bindable } from 'aurelia-framework';

export class SiteDetailImages extends ReactWrapper<ISiteDetailsGalleryProps> {
  @bindable()
  siteId : number;

  constructor() {
    super(SiteDetailImagesReact, () => ({
      siteId: this.siteId
    }));
  }
}