import { customElement, autoinject, bindable } from 'aurelia-framework';
import {
  BaseViewModel,
  getTimezoneForSiteSelector
} from '../../../../../common';
import { getLogger } from 'aurelia-logging';
import {
  ISiteExportDetails,
  ISiteChannel,
  AccessLevel,
  INote
} from '../../../../../../interfaces';
import { IAsyncEntity } from '../../../../../../types';
import {
  ExportJobService,
  ChannelService,
  ExportMasterDataService,
  NoteService,
  SiteService,
  BuidService
} from '../../../../../../services';
import {
  removeNoneFromArray,
  userHasFeature,
  toObject
} from '../../../../../../utility';
import { rootState } from '../../../../../../reducers';
import './sitedetailsexports.css';
import { getSession } from '../../../../../../config/sessionService';
import { features } from '../../../../../../config';
import { NotificationService } from '../../../../../../services/notificationService';

interface SiteDetailsExportsState {
  exports: IAsyncEntity<ISiteExportDetails[]>;
  timezone?: string;
  access: {
    canSendMasterData: boolean;
    siteBuidIsEnabledForPdiMasterDataExport: boolean;
  };
}

@autoinject()
@customElement('sitedetails-exports')
export class SitedetailsExports extends BaseViewModel<SiteDetailsExportsState> {
  constructor(
    private exportJobService: ExportJobService,
    private channelService: ChannelService,
    private siteService: SiteService,
    private buidService: BuidService,
    private exportMasterDataService: ExportMasterDataService,
    private notification: NotificationService,
    private noteService: NoteService
  ) {
    super(getLogger('SiteDetailsExports'));
  }

  @bindable siteId: number | undefined;

  siteIdChanged() {
    if (this.siteId) this.attachMapState(this.mapState(this.siteId));
  }

  bind() {
    this.siteIdChanged();
  }

  mapState = (siteId: number) => (
    state: rootState
  ): SiteDetailsExportsState => {
    const exports = this.exportJobService.getExportedChannelsForSite(siteId);
    const siteChannels = this.channelService.getSiteChannelsForSite(siteId);
    const site = this.siteService.getSiteById(siteId).getEntityOrUndefined();

    let pdiMasterDataExportEnabled = false;

    //Check that site's BUID has pdiMasterDataExportEnabled
    if (site && site.buidId) {
      const buids = this.buidService.getAllBuids().getEntityOrUndefined();
      if (buids !== undefined) {
        const buidMap = toObject(buids, b => b.buidId);
        const buid = buidMap[site.buidId];
        if (buid) pdiMasterDataExportEnabled = buid.pdiMasterDataExportEnabled;
      }
    }

    return {
      exports: exports
        .map2<ISiteExportDetails[], ISiteChannel[]>(siteChannels, (es, scs) =>
          es.map<ISiteExportDetails>(e => ({
            ...e,
            siteChannels: removeNoneFromArray(
              e.siteChannelIds.map(sci =>
                scs.find(sc => sc.siteChannelId === sci)
              )
            )
          }))
        )
        .getAsyncEntity(),
      timezone: getTimezoneForSiteSelector(state),
      access: {
        canSendMasterData: userHasFeature(
          getSession(),
          features.sendPdiMasterData,
          AccessLevel.Read
        ),
        siteBuidIsEnabledForPdiMasterDataExport: pdiMasterDataExportEnabled
      }
    };
  };

  sendMasterData(): Promise<void | INote[] | undefined> {
    return this.exportMasterDataService
      .sendPdiMasterData(this.siteId || 0)
      .then(_ => {
        this.notification.notify({
          type: 'CUSTOM',
          level: 'info',
          text: 'Masterdata sucessfully sent to PDI',
          timestamp: new Date().toString(),
          acknowledged: false
        });

        if (this.siteId)
          return this.noteService.getAllNotesForSiteNoCache(this.siteId);
        else return;
      })
      .catch(reason => {
        this.notification.notify({
          type: 'CUSTOM',
          level: 'error',
          text: reason,
          timestamp: new Date().toString(),
          acknowledged: false
        });
      });
  }
}
