import { customElement, bindable, containerless } from 'aurelia-framework';
import './contactsview.css';
import {
  SitedetailsContactFragment,
  ContactType
} from '../../../../../custom_typings/graphql';
import { IContact } from '../../../../interfaces/entity/icontact';
import { BaseViewModel } from '../../BaseViewModel';

@containerless()
@customElement('contacts-view')
export class ContactsView extends BaseViewModel<void> {
  @bindable() loading: boolean;
  @bindable() changeContact: Function;
  @bindable() removeContact: Function;

  @bindable() orderContact: IContact;
  @bindable() techContact: IContact;
  @bindable() salesContact: IContact;
  @bindable() customerContact: IContact;
  @bindable() transportContact: IContact;
  @bindable() dealerContact: IContact;

  contactTypes = ContactType;

  /** For backwardscompatiblity */
  defaultUser: SitedetailsContactFragment = {
    avatarUrl: '/images/profile/default.jpg',
    name: 'Not set',
    userId: '0',
    email: '',
    telephoneNumber: null
  };

  hasContacts() {
    return (
      this.customerContact ||
      this.orderContact ||
      this.techContact ||
      this.salesContact ||
      this.transportContact ||
      this.dealerContact
    );
  }
}
