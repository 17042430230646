import { GraphQLBaseViewModel } from '../../../../../common/GraphQLBaseViewModel';
import gql from 'graphql-tag';
import {
  SiteDetailsUsersWithAccessQuery,
  SiteDetailsUsersWithAccessQueryVariables
} from '../../../../../../../custom_typings/graphql';
import { bindable, customElement } from 'aurelia-framework';
import './sitedetailsuserswithaccess.css';

@customElement('site-details-users-with-access')
export class SiteDetailsUsersWithAccess extends GraphQLBaseViewModel<
  void,
  SiteDetailsUsersWithAccessQuery,
  SiteDetailsUsersWithAccessQueryVariables
> {
  @bindable()
  siteId: number;

  query = gql`
    query SiteDetailsUsersWithAccessQuery($siteId: Int!) {
      site(siteId: $siteId) {
        siteId
        users {
          userId
          name
          currentUserHasAccess
          internalUser
          customer {
            customerId
            name
          }
          buid {
            buidId
            name
          }
        }
      }
    }
  `;

  bind() {
    this.variables = {
      siteId: this.siteId
    };
  }
}
