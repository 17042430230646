import { default as React, FC, useState } from 'react';
import './sitedetailimages.css';
import { ReactSiteGalleryQueryDocument } from '$typings/graphql-codegen';
import { useQuery } from '$lib/hooks/fetch-utillities';
import LoadingBarsReact from '$components/loading-bars/loading-bars.react';
import Icon from '$components/icons/icon/icon.react';
import { isSomething } from '$lib/helpers';
import classNames from 'classnames';

export interface ISiteDetailsGalleryProps {
  siteId: number;
}

const SiteDetailImagesReact: FC<ISiteDetailsGalleryProps> = ({siteId}) => {
  const { data, loading } = useQuery(ReactSiteGalleryQueryDocument, {siteId});
  const [imageIndex, setImageIndex] = useState<number>(0);

  const previousImage = () => {
    if (imageIndex > 0)
      setImageIndex(imageIndex-1);
  }

  const nextImage = () => {
    const totalImages : number = data?.site?.galleryItems?.length || 0;

    if (imageIndex < totalImages-1) {
      setImageIndex(imageIndex+1);
    }    
  }

  return (
    <div className="sitedetails-images-wrapper">
        {loading && (
          <LoadingBarsReact center />
        )}

        {!loading 
          && data?.site?.galleryItems[imageIndex]?.thumbnailUrl  
          && isSomething(data?.site?.galleryItems[imageIndex]?.thumbnailUrl)
          && (
            <>
              <div onClick={() => previousImage()}>
                <Icon name='fa-chevron-left' className={classNames(imageIndex <= 0 ? 'left disabled' : 'left')} />
              </div>
              <div className='imageWrapper'>                
                <a href={`sitedetails/${siteId}/gallery/${data.site.galleryItems[imageIndex].siteGalleryItemId}`}>
                  <img className="imageThumbnail"                    
                    src={data.site.galleryItems[imageIndex].thumbnailUrl || undefined} />
                </a>
              </div>
              <div onClick={() => nextImage()}>
                <Icon name='fa-chevron-right' className={classNames(
                  ((data?.site?.galleryItems?.length || 0) <= imageIndex) ? (
                    'right disabled'
                  ) : (
                    'right'
                  )
                )} />
              </div>
            </>
        )}        
    </div>
  );
};

export default SiteDetailImagesReact;
