import { autoinject } from 'aurelia-framework';
import {
  getAllowedColumns,
  getTimezoneForSiteSelector,
  IAllowedColumns,
  nativeControllersForSiteSelector,
  selectProducts
} from '$pages/common';
import { rootState } from '../../../../reducers';
import {
  appendOrReplaceObjectInArray,
  asyncEntityIsFetched,
  calculateOutsideThreshold,
  channelHasNoConsumption,
  channelIsInErrorState,
  channelIsTankChannel,
  doWithFetchedAsyncEntity,
  emptyArray,
  ensureNumber,
  entityIsOffline,
  getAsyncEntitiesByAsyncArray,
  getAsyncEntity,
  getEntityOrDefault,
  getSiteSignal,
  isEmpty,
  isNone,
  isSomething,
  mergeObjects,
  orderByPredicate,
  toObject
} from '../../../../utility';
import {
  AlarmService,
  BuidService,
  ChannelService,
  ControllerService,
  CustomerService,
  PinnedSiteService,
  ProductService,
  SiteService,
  UnitService
} from '../../../../services';
import { IAsyncEntity, menuIsOpenForEntity } from '../../../../types';
import {
  AccessLevel,
  IBuid,
  ICustomer,
  IMultiSiteChannelEdit,
  INote,
  ISiteChannel,
  ISiteDetails,
  ISiteTankChannelWithStatus,
  IUser
} from '../../../../interfaces';
import {
  modifyPropertyOnSite,
  setEditSiteChannelParkedDescription,
  setEditSiteChannelStatus,
  toggleEditSiteChannelStatus,
  toggleEditSiteDetails,
  toggleExpandMenuOnEntity
} from '../../../../actions';
import { getLogger } from 'aurelia-logging';
import { Router } from 'aurelia-router';
import { ColumnAccess, features, routeNames } from '../../../../config/index';
import { hasAccessToBuid, userHasFeature } from '$lib/authorizationHelper';
import { getSession } from '../../../../config/sessionService';
import './sitedetailsindex.css';
import { GraphQLBaseViewModel } from '$pages/common';
import gql from 'graphql-tag';
import { SiteDetailsTabEnum } from '$typings/graphql-codegen';
import { SiteDetailsUserSettingsQuery, SiteDetailsUserSettingsQueryVariables } from '$typings/graphql';

interface ISiteDetailsIndexState {
  siteId: number;
  currentlyEditingSite: boolean;
  siteDetails: IAsyncEntity<ISiteDetails>;
  menuIsOpenForEntity?: menuIsOpenForEntity;
  buids: IBuid[];
  customers: ICustomer[];
  isMobile: boolean;
  products: {};
  columns: IAllowedColumns;
  statusEdit: boolean;
  statusEditParkedDescription: string | undefined;
  statusEditParked: boolean | undefined;
  channels: IAsyncEntity<ISiteChannel[]>;
  timezone?: string;
  isSaving: boolean;
}

const invalidModel = (alias: string) => isEmpty(alias);

@autoinject()
export class SiteDetailsIndex extends GraphQLBaseViewModel<
  ISiteDetailsIndexState,
  SiteDetailsUserSettingsQuery,
  SiteDetailsUserSettingsQueryVariables
> {
  columns = ColumnAccess;

  deleteConfirmation = false;
  shouldDeleteControllers = false;
  alsoSuspendSimCards = false;
  usersWithSiteAccessExpanded = false;
  usersWithSiteAccessCard: Element;
  hasAccessToCustomerManager = false;
  hasAccessToSiteGallery = false;
  isSaving = false;
  siteId: number;
  siteDetailsDefaultTab: SiteDetailsTabEnum | undefined = undefined;

  constructor(
    private router: Router,
    private pinnedSitesService: PinnedSiteService,
    private siteService: SiteService,
    private customerService: CustomerService,
    private controllerService: ControllerService,
    private buidService: BuidService,
    private productService: ProductService,
    private channelService: ChannelService,
    private alarmService: AlarmService,
    private unitService: UnitService,
  ) {
    super(getLogger('SiteDetailsIndex'));
  }
  
  query = gql`
    query SiteDetailsUserSettingsQuery($siteId: ID!) {
      currentUser {
        userSettings {
          siteDetailsDefaultTab
        }
      }
      siteChannels(siteIds: [$siteId], channelTypes: [Tank]) {
        edges {
          siteChannelId
        }
      }
    }
  `
  dataChanged = () => {
    if (this.data?.currentUser.userSettings?.siteDetailsDefaultTab) {
      this.siteDetailsDefaultTab = this.data?.currentUser.userSettings?.siteDetailsDefaultTab
      this.navigateToDefaultTab();
    }      
  }

  activate(params: { id: string; action: 'edit' | undefined }) {
    const siteId = ensureNumber(params.id);
    this.siteId = siteId;
    this.attachMapState(this.mapState(siteId, params.action === 'edit'));
    this.hasAccessToCustomerManager = userHasFeature(getSession(), features.manageCustomers, AccessLevel.Read);
    this.hasAccessToSiteGallery = userHasFeature(getSession(), features.siteGallery, AccessLevel.Read);

    this.variables = { siteId: this.siteId.toString() }
  }

  
  
  navigateToDefaultTab = () => {

    this.siteDetailsDefaultTab = this.data?.currentUser.userSettings?.siteDetailsDefaultTab;

    //Override if we actually want to stay on SiteDetails 
    if (window.location.href.endsWith("details")) {
      this.siteDetailsDefaultTab = SiteDetailsTabEnum.Details;
    }

    if (this.siteDetailsDefaultTab && this.siteDetailsDefaultTab != SiteDetailsTabEnum.Details) {
      
      if (window.location.href.endsWith("details"))
        return;

      if (window.location.href.indexOf("sitedetails") < 0)
        return;

      if (this.siteDetailsDefaultTab == SiteDetailsTabEnum.Graph)
        this.router.navigateToRoute(routeNames.sitedetailsGraph);
      else if (this.siteDetailsDefaultTab == SiteDetailsTabEnum.Tanks && this.state) {
        if (this.data?.siteChannels?.edges && this.data?.siteChannels?.edges[0]) {
          const firstTankSiteChannelId = this.data?.siteChannels?.edges[0].siteChannelId;
          if (firstTankSiteChannelId) {
            this.router.navigate(`tanks/tankDetails/${firstTankSiteChannelId}`);
          }
        }
      }
    }
  } 
  
  mapState = (siteId: number, editingThroughUrl: boolean) => (
    state: rootState
  ): ISiteDetailsIndexState => {
    const siteFetcher = this.siteService.getSiteById(siteId);
    const pendingSiteDetails = state.sitedetails.pendingSiteDetails;
    const editingSite = editingThroughUrl || !isNone(pendingSiteDetails);

    const mergedPendingDetails = siteFetcher.map(site =>
      isNone(pendingSiteDetails) ? site : mergeObjects(site, pendingSiteDetails)
    );
    const buidsFetcher = this.buidService.getAllBuids();
    const unitsFetcher = this.unitService.fetchUnits();
    const siteChannelFetcher = this.channelService.getSiteChannelsForSite(
      siteId
    );
    const siteCustomerFetcher = mergedPendingDetails.bind(details =>
      this.customerService.getByToken(
        details.customerId,
        details.customerIdToken
      )
    );
    const originalCustomerFetcher = siteFetcher.bind(site =>
      this.customerService.getByToken(site.customerId, site.customerIdToken)
    );
    const productsFetcher = this.productService.getAll();
    const pinnedSitesFetcher = this.pinnedSitesService.getAllIds();
    const controllersFetcher = this.controllerService.getControllersBySiteId(
      siteId
    );

    const controllers = controllersFetcher.getEntityOrDefault(emptyArray);
    const products = productsFetcher.getEntityOrDefault(emptyArray);
    
    const siteDetails = mergedPendingDetails
      .map3(buidsFetcher, unitsFetcher, (site, buids, units) => {
        const buid = buids.find(b => b.buidId === site.buidId);

        const siteChannels = siteChannelFetcher.getEntityOrDefault(emptyArray);
        const tankChannels = siteChannels.filter(channelIsTankChannel).map(
          (tank): ISiteTankChannelWithStatus => ({
            ...tank,
            outsideThreshold: calculateOutsideThreshold(tank),
            noConsumption: channelHasNoConsumption(tank),
            error: channelIsInErrorState(tank),
            isOffline: entityIsOffline(tank),
            unit: units.find(u => u.unitId === tank.unitId),
            product: products.find(p => p.productId === tank.productId)
          })
        );

        const customer = siteCustomerFetcher.getEntityOrUndefined();
        const allAlarms = this.alarmService
          .getAlarmsBySiteId(siteId)
          .getEntityOrDefault(emptyArray);
        const activeAlarms = allAlarms.filter(a => a.active);

        return {
          ...site,
          controllerIds: nativeControllersForSiteSelector(state).map(
            c => c.controllerId
          ),
          buid,
          tankChannels,
          tankChannelsWithShipTo: tankChannels.filter(
            sc => !isEmpty(sc.tankDetails.shipTo)
          ),
          isPinned: pinnedSitesFetcher
            .map(pinned => pinned.some(ps => ps === siteId))
            .getAsyncEntity(),
          notes: getAsyncEntitiesByAsyncArray(
            getAsyncEntity(state.notes.notesForSites, site.siteId),
            state.notes.byId
          ),
          customer,
          invalidModel: invalidModel(site.alias),
          alarms: activeAlarms,
          siteStatus: getSiteSignal(
            activeAlarms,
            site,
            siteChannels,
            controllers
          )
        } as ISiteDetails;
      })
      .getAsyncEntity();

    const buids = buidsFetcher
      .map2(mergedPendingDetails, (buids, site) =>
        buids
          .filter(buid => buid.name !== 'missing_buid')
          .filter(
            buid =>
              site.buidId === buid.buidId || hasAccessToBuid(getSession(), buid)
          )
      )
      .getEntityOrDefault(emptyArray);

    const customers = !editingSite
      ? emptyArray
      : this.customerService
          .getAll()
          .map2(originalCustomerFetcher, (customers, originalCustomer) =>
            appendOrReplaceObjectInArray(
              customers,
              c => c.customerId === originalCustomer.customerId,
              originalCustomer
            )
          )
          .map(customers => orderByPredicate(customers, c => c.name, 'asc'))
          .getEntityOrDefault(emptyArray);

    return {
      siteId,
      siteDetails,
      isSaving: this.isSaving,
      currentlyEditingSite: editingSite,
      menuIsOpenForEntity: state.application.menuIsOpenForEntity,
      buids,
      customers,
      isMobile: state.device.screenSize === 'mobile',
      products: toObject(
        getEntityOrDefault(selectProducts(state), []),
        p => p.productId
      ),
      columns: getAllowedColumns(getSession()),
      statusEdit: state.sitedetails.statusEdit,
      statusEditParked: state.sitedetails.statusEditParked,
      statusEditParkedDescription:
        state.sitedetails.statusEditParkedDescription,
      channels: siteChannelFetcher.getAsyncEntity(),
      timezone: getTimezoneForSiteSelector(state),
    };
  };

  toggleExpandMenuOnEntity(entity: INote | IUser | number) {
    this.dispatch(toggleExpandMenuOnEntity(entity));
  }

  getParkedDescription() {
    let parkedDescription = '';

    if (this.state.channels?.fetched) {
      this.state.channels?.entity.forEach(channel => {
        if (channel.isParked && (channel.parkedDescription !== undefined && channel.parkedDescription !== ''))
          parkedDescription = channel.parkedDescription;
      });
    }

    return parkedDescription;
  }

  async togglePinSite(siteId: number, siteIsPinned: boolean) {
    siteIsPinned
      ? this.pinnedSitesService.remove(siteId)
      : this.pinnedSitesService.add(siteId);
    this.revalidateAllActiveQueries();
  }

  toggleSiteDetailsEdit() {
    this.state.isMobile
      ? this.router.navigate('details/edit')
      : this.dispatch(toggleEditSiteDetails());
  }

  goToChannels() {
    this.router.navigateToRoute(routeNames.sitedetailsTanks, undefined, {
      replace: !this.state.isMobile
    });
  }

  goToAlarms() {
    if (!this.features.alarms.read) return;
    this.router.navigateToRoute(routeNames.sitedetailsAlarms, undefined, {
      replace: !this.state.isMobile
    });
  }

  cancelSiteDetailsEdit() {
    this.state.isMobile
      ? this.router.navigateBack()
      : this.dispatch(toggleEditSiteDetails());
  }

  async savePendingSiteDetailsChanges(siteDetails: ISiteDetails) {
    await this.siteService.updateSite(siteDetails);
    await this.revalidateAllActiveQueries();
    if (this.state.isMobile) this.router.navigateBack();
    else this.dispatch(toggleEditSiteDetails());
  }

  gotoTank(siteChannelId: number) {
    this.router.navigate(
      routeNames.sitedetailsTanksHref(this.state.siteId, siteChannelId)
    );
  }

  public changePropertyOnSiteDetails<K extends keyof ISiteDetails>(
    property: K,
    value: string | number
  ) {
    doWithFetchedAsyncEntity(this.state.siteDetails, details => {
      if (details[property] === value) return;
      this.dispatch(
        modifyPropertyOnSite({ siteId: this.state.siteId, property, value })
      );
    });
  }

  changeParkedReason = (value: string) =>
    this.dispatch(setEditSiteChannelParkedDescription(value));

  changeParkedStatus = (newValue: boolean) =>
    this.dispatch(setEditSiteChannelStatus(newValue));

  toggleEditSiteChannels = () => this.dispatch(toggleEditSiteChannelStatus());

  persistStatusEdit() {    
    const {
      channels,
      statusEditParked,
      statusEditParkedDescription
    } = this.state;
        
    if (!asyncEntityIsFetched(channels)) return;
    
    if (statusEditParked === undefined)
      return;
    
    if (!channels.entity.length)
      return;
    
    const multiEditDTO : IMultiSiteChannelEdit = {
      siteChannelIds: channels.entity.map<number>(c => c.siteChannelId),
      parked: statusEditParked,
      parkedDescription: statusEditParkedDescription || ''      
    };

    this.isSaving = true;

    this.channelService.updateSiteChannels(this.state.siteId, multiEditDTO);

    this.isSaving = false;
  }

  canAlsoSuspendSimCards = false;
  async deleteControllerButtonClick() {
    const details = getEntityOrDefault(this.state.siteDetails, undefined);
    const controllerIds = isSomething(details) ? details.controllerIds : [];
    const deleteDialogInfo = await this.controllerService.getDeleteDialogInfo(controllerIds);
    this.canAlsoSuspendSimCards = deleteDialogInfo.canSuspendSims;
    this.deleteConfirmation = true;
  }

  deleteSite = async (shouldDeleteControllers: boolean, alsoSuspendSimCards: boolean) => {
    const details = getEntityOrDefault(this.state.siteDetails, undefined);
    const controllerIds = isSomething(details) ? details.controllerIds : [];

    this.router.navigateToRoute(routeNames.dashboard);

    await this.siteService.deleteSite(
      this.state.siteId,
      controllerIds,
      shouldDeleteControllers,
      alsoSuspendSimCards
    );

    this.alsoSuspendSimCards = false;
  };

  toggleShouldDeleteControllers = () =>
    (this.shouldDeleteControllers = !this.shouldDeleteControllers);

  toggleSiteAccessUsersExpansion = () =>
    (this.usersWithSiteAccessExpanded = !this.usersWithSiteAccessExpanded);
}
