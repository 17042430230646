import { customElement, bindable, containerless } from 'aurelia-framework';
import './sitedetailcontactssearch.css';
import {
  ContactType
} from '../../../../../../../../../custom_typings/graphql';

import { GraphQLBaseViewModel } from '../../../../../../../common/GraphQLBaseViewModel';
import { IContact } from '../../../../../../../../interfaces/entity/icontact';
import { setSiteContact } from '../sitedetailcontactsutilities';

@containerless()
@customElement('sitedetailcontacts-search')
export class SitedetailscontactsSearch extends GraphQLBaseViewModel<
  void,
  void,
  void
> {
  @bindable() cancelSearch: Function;
  @bindable() contactType: ContactType;
  @bindable() siteId: number;
  @bindable() setContactDone: Function;
  @bindable() createNewContact: Function;

  setAsContact = async (contact: IContact) => {
    try {
      await setSiteContact(this.siteId, contact.contactId, this.contactType);

      this.setContactDone();
    } catch(error){
      this.logger.error(JSON.stringify(error));
      throw error;
    }
  };
}
