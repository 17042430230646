import { gql } from "graphql-request";
import { ContactType, SitedetailsAssignContactMutation, SitedetailsAssignContactMutationVariables } from "../../../../../../../../custom_typings/graphql";
import { runSingleQuery, revalidateAllActiveQueries } from "../../../../../../common/GraphQLFetcher";


export const setSiteContact = async (siteId: number, contactId: number, contactType: ContactType) => {
    await runSingleQuery<SitedetailsAssignContactMutation,
                        SitedetailsAssignContactMutationVariables>(gql`
        mutation SitedetailsAssignContactMutation(
            $siteId: ID!
            $contactType: ContactType!
            $contactId: ID!
        ) {
            setContactOnSite(
            contactType: $contactType
            siteId: $siteId
            contactId: $contactId
            )
        }
        `,
        {
        siteId: siteId.toString(),
        contactId: contactId.toString(),
        contactType: contactType
        });

    await revalidateAllActiveQueries();
  };
